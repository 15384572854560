export const environment = {
  production: false,
  staging: false,
  dev: true,
  siteUrl: 'https://mynitel.dev.nitelusa.com',
  apiUrl: 'https://mynitel.dev.nitelusa.com/api/v2',
  mocks: false,
  entra_tenant_id: "3b07c292-8989-432b-84cf-ac7772d4cb9b",
  entra_spa_client_id: "35029cc6-3134-4edd-aecb-63fa0a54f48b",
  entra_nitelapi_scopes: "https://mynitel.onmicrosoft.com/myniteldev/readwrite",

};
