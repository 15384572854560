import { ChartConfiguration, ChartData } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

//#region     BEGIN OPTIONS
const defaultChartOptions = (): ChartConfiguration["options"] => ({
  indexAxis: "y",
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: true,
      },
      position: 'top',
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "end",
      align: "end",
      font: {
        size: 12,
        weight: "bolder",
        family: '"open-sans", sans-serif',
      },
      color: "#000",
      formatter: (value) => {
        return (value || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
      },
    },
  },
});
//#endregion  END OPTIONS

//#region     BEGIN DATA
export type DefaultChartDataOptions = {
  datasetsCount?: number;
  bgColors?: string[][];
  borders?: string[][];
  labels?: string[];
}
const defaultChartData = ({ datasetsCount, bgColors, borders, labels }: DefaultChartDataOptions = {}): ChartData<'bar'> => ({
  labels: [],
  datasets: Array.from({ length: Math.max(1, datasetsCount || 0) }, (_, i) => ({
    data: [],
    label: labels?.[i] || '',
    backgroundColor: bgColors?.[i % bgColors.length] || [
      'rgba(44, 142, 250, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(255, 99, 132, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(153, 102, 255, 0.8)',
    ],
    borderColor: borders?.[i % borders.length] || [
      'rgb(44, 142, 250)',
      'rgb(54, 162, 235)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(255, 159, 64)',
      'rgb(255, 99, 132)',
      'rgb(153, 102, 255)',
    ],
    borderWidth: 0,
  })),
});
//#endregion  BEGIN DATA

//#region     BEGIN DATA
const defaultChartPlugins = (): any[] => [DataLabelsPlugin];
//#endregion  BEGIN DATA

export const chartDefaults = Object.freeze({
  plugins: defaultChartPlugins,
  options: defaultChartOptions,
  data: defaultChartData,
});
